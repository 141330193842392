import './App.css';
import { WebStatus } from './Components/WebStatus/WebStatus'

function App() {
  return (
    <>
      <WebStatus />
    </>
  );
}

export default App;
